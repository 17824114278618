export const MoonTonLogo = () => (
  <svg
    width="128"
    height="57"
    viewBox="0 0 128 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97.6278 43.4365L96.4489 43.6772V32.2334H100.151V46.1215H95.3437L87.3676 34.6962L88.5281 34.4555V46.1215H84.8256V32.2334H89.7623L97.6278 43.4365Z"
      fill="#F1F1F1"
    />
    <path
      d="M74.642 46.4177C73.0087 46.4177 71.572 46.1152 70.3317 45.5103C69.1036 44.9054 68.1458 44.0598 67.4581 42.9734C66.7827 41.8871 66.4449 40.6217 66.4449 39.1773C66.4449 37.733 66.7827 36.4676 67.4581 35.3813C68.1458 34.2949 69.1036 33.4493 70.3317 32.8444C71.572 32.2395 73.0087 31.937 74.642 31.937C76.2876 31.937 77.7244 32.2395 78.9524 32.8444C80.1804 33.4493 81.1383 34.2949 81.826 35.3813C82.5137 36.4676 82.8575 37.733 82.8575 39.1773C82.8575 40.6217 82.5137 41.8871 81.826 42.9734C81.1383 44.0598 80.1804 44.9054 78.9524 45.5103C77.7244 46.1152 76.2876 46.4177 74.642 46.4177ZM74.642 42.9919C75.5385 42.9919 76.3121 42.8376 76.963 42.529C77.6138 42.2204 78.1112 41.7821 78.455 41.2143C78.8112 40.6464 78.9892 39.9674 78.9892 39.1773C78.9892 38.3873 78.8112 37.7083 78.455 37.1404C78.1112 36.5726 77.6138 36.1343 76.963 35.8257C76.3121 35.5171 75.5385 35.3627 74.642 35.3627C73.7578 35.3627 72.9903 35.5171 72.3395 35.8257C71.6886 36.1343 71.1851 36.5726 70.829 37.1404C70.4729 37.7083 70.2948 38.3873 70.2948 39.1773C70.2948 39.9674 70.4729 40.6464 70.829 41.2143C71.1851 41.7821 71.6886 42.2204 72.3395 42.529C72.9903 42.8376 73.7578 42.9919 74.642 42.9919Z"
      fill="#F1F1F1"
    />
    <path
      d="M56.8827 33.9185H60.7142V46.1215H56.8827V33.9185ZM51.6145 32.2334H65.9824V35.6406H51.6145V32.2334Z"
      fill="#F1F1F1"
    />
    <path
      d="M125.432 21.2681L124.232 21.5131V9.86572H128V24.0009H123.107L114.989 12.3724L116.17 12.1273V24.0009H112.402V9.86572H117.426L125.432 21.2681Z"
      fill="#F1F1F1"
    />
    <path
      d="M102.037 24.3022C100.375 24.3022 98.9122 23.9944 97.6499 23.3787C96.4 22.763 95.4251 21.9024 94.7252 20.7967C94.0377 19.691 93.694 18.4031 93.694 16.9331C93.694 15.463 94.0377 14.1752 94.7252 13.0695C95.4251 11.9638 96.4 11.1031 97.6499 10.4875C98.9122 9.8718 100.375 9.56396 102.037 9.56396C103.712 9.56396 105.174 9.8718 106.424 10.4875C107.674 11.1031 108.649 11.9638 109.349 13.0695C110.049 14.1752 110.399 15.463 110.399 16.9331C110.399 18.4031 110.049 19.691 109.349 20.7967C108.649 21.9024 107.674 22.763 106.424 23.3787C105.174 23.9944 103.712 24.3022 102.037 24.3022ZM102.037 20.8155C102.949 20.8155 103.737 20.6585 104.399 20.3444C105.062 20.0302 105.568 19.5842 105.918 19.0062C106.28 18.4283 106.461 17.7372 106.461 16.9331C106.461 16.1289 106.28 15.4379 105.918 14.8599C105.568 14.282 105.062 13.8359 104.399 13.5218C103.737 13.2077 102.949 13.0506 102.037 13.0506C101.137 13.0506 100.356 13.2077 99.6934 13.5218C99.031 13.8359 98.5185 14.282 98.1561 14.8599C97.7936 15.4379 97.6124 16.1289 97.6124 16.9331C97.6124 17.7372 97.7936 18.4283 98.1561 19.0062C98.5185 19.5842 99.031 20.0302 99.6934 20.3444C100.356 20.6585 101.137 20.8155 102.037 20.8155Z"
      fill="#F1F1F1"
    />
    <path
      d="M83.948 24.3022C82.2857 24.3022 80.8233 23.9944 79.5609 23.3787C78.3111 22.763 77.3362 21.9024 76.6362 20.7967C75.9488 19.691 75.6051 18.4031 75.6051 16.9331C75.6051 15.463 75.9488 14.1752 76.6362 13.0695C77.3362 11.9638 78.3111 11.1031 79.5609 10.4875C80.8233 9.8718 82.2857 9.56396 83.948 9.56396C85.6228 9.56396 87.0852 9.8718 88.335 10.4875C89.5849 11.1031 90.5598 11.9638 91.2597 13.0695C91.9597 14.1752 92.3096 15.463 92.3096 16.9331C92.3096 18.4031 91.9597 19.691 91.2597 20.7967C90.5598 21.9024 89.5849 22.763 88.335 23.3787C87.0852 23.9944 85.6228 24.3022 83.948 24.3022ZM83.948 20.8155C84.8604 20.8155 85.6478 20.6585 86.3102 20.3444C86.9727 20.0302 87.4789 19.5842 87.8288 19.0062C88.1913 18.4283 88.3725 17.7372 88.3725 16.9331C88.3725 16.1289 88.1913 15.4379 87.8288 14.8599C87.4789 14.282 86.9727 13.8359 86.3102 13.5218C85.6478 13.2077 84.8604 13.0506 83.948 13.0506C83.0481 13.0506 82.2669 13.2077 81.6045 13.5218C80.942 13.8359 80.4296 14.282 80.0671 14.8599C79.7047 15.4379 79.5234 16.1289 79.5234 16.9331C79.5234 17.7372 79.7047 18.4283 80.0671 19.0062C80.4296 19.5842 80.942 20.0302 81.6045 20.3444C82.2669 20.6585 83.0481 20.8155 83.948 20.8155Z"
      fill="#F1F1F1"
    />
    <path
      d="M73.5929 9.86572V24.0009H69.8995V11.2604L70.7245 11.3546L65.2875 24.0009H61.3504L55.8947 11.3923L56.7384 11.2981V24.0009H53.045V9.86572H59.0444L64.0876 22.0031H62.5878L67.5935 9.86572H73.5929Z"
      fill="#F1F1F1"
    />
    <path
      d="M22.8462 56.0001L44.5936 42.1697V35.3419L22.8462 49.0127M22.8462 56.0001V49.0127M22.8462 56.0001L1.10162 42.3445V35.2837L22.8462 49.0127M17.8684 38.6836L22.8462 35.3925L27.8268 38.6836"
      stroke="url(#paint0_linear_1_282)"
      strokeWidth="0.252167"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3473 27.939L1 35.1399L22.5428 48.8108V41.5196L12.6859 35.2411L18.2452 31.6429L12.3473 27.939Z"
      fill="url(#paint1_linear_1_282)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4201 31.6239L33.0093 35.2414L23.1525 41.5199V48.8111L44.7968 35.2414L33.2673 27.9053L27.4201 31.6239Z"
      fill="url(#paint2_linear_1_282)"
    />
    <path
      d="M22.5429 27.3423V20.0511L12.686 13.7726L22.5429 7.39289V0.000488281L1.00012 13.6714L22.5429 27.3423Z"
      fill="url(#paint3_linear_1_282)"
    />
    <path
      d="M23.1523 27.3423V20.0511L33.0091 13.7726L23.1523 7.39289V0.000488281L44.7967 13.7726L23.1523 27.3423Z"
      fill="url(#paint4_linear_1_282)"
    />
    <path
      d="M22.8463 34.5319L44.5937 20.7014V13.8736L22.8463 27.5445M22.8463 34.5319V27.5445M22.8463 34.5319L1.10175 20.8762V13.8154L22.8463 27.5445M17.8685 17.2154L22.8463 13.9243L27.827 17.2154"
      stroke="url(#paint5_linear_1_282)"
      strokeWidth="0.252167"
      strokeLinecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_282"
        x1="22.8476"
        y1="35.2837"
        x2="22.8476"
        y2="56.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#078FD1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_282"
        x1="11.7714"
        y1="27.939"
        x2="11.7714"
        y2="48.8108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#078FD1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_282"
        x1="33.9746"
        y1="27.9053"
        x2="33.9746"
        y2="48.8111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#078FD1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_282"
        x1="11.7715"
        y1="0.000488281"
        x2="11.7715"
        y2="27.3423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#078FD1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_282"
        x1="33.9745"
        y1="0.000488281"
        x2="33.9745"
        y2="27.3423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#078FD1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_282"
        x1="22.8477"
        y1="13.8154"
        x2="22.8477"
        y2="34.5319"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#078FD1" />
      </linearGradient>
    </defs>
  </svg>
);