import type { ReactNode } from 'react';

import './Upload.css';

type UploadButtonProps = {
  onClick: VoidFunction;
  children: ReactNode;
};

export const UploadButton = ({ onClick, children }: UploadButtonProps) => {
  //TODO поигратья с плавностью кнопок
  // TODO переименовать className
  return (
    <button
      className="upload"
      onClick={onClick}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.71297 0.551218C7.56435 0.615067 3.39659 4.77348 3.30452 4.94981C3.20922 5.13233 3.21398 5.39474 3.31619 5.59337C3.42737 5.80941 3.60995 5.9306 3.85042 5.94799C4.20532 5.97366 4.14642 6.02124 5.79144 4.38062L7.29836 2.87769V7.18669C7.29836 11.9046 7.28873 11.6604 7.48299 11.865C7.62239 12.0118 7.78265 12.0778 8 12.0778C8.31854 12.0778 8.57834 11.8919 8.66525 11.6019C8.69172 11.5135 8.70164 10.3097 8.70164 7.17904V2.87769L10.2086 4.38062C11.8536 6.02124 11.7947 5.97366 12.1496 5.94799C12.3899 5.9306 12.5727 5.80938 12.6836 5.59375C12.7843 5.39816 12.7885 5.1294 12.6938 4.94723C12.5981 4.76311 8.43262 0.613089 8.28008 0.54991C8.13073 0.488039 7.85865 0.488676 7.71297 0.551218ZM0.9039 8.72577C0.747563 8.79813 0.620439 8.92962 0.554772 9.08685C0.483365 9.25776 0.481068 12.378 0.552061 12.7832C0.790554 14.1451 1.85041 15.205 3.21232 15.4435C3.61196 15.5135 12.388 15.5135 12.7877 15.4435C14.1496 15.205 15.2094 14.1451 15.4479 12.7832C15.5189 12.378 15.5166 9.25776 15.4452 9.08685C15.2389 8.59293 14.5357 8.51865 14.2306 8.95855L14.1393 9.09023L14.1199 10.7993C14.0991 12.6329 14.0994 12.6301 13.924 13.0078C13.6859 13.5208 13.1994 13.9142 12.6244 14.0589C12.3638 14.1244 3.6362 14.1244 3.37558 14.0589C2.80062 13.9142 2.31406 13.5208 2.07595 13.0078C1.90064 12.6301 1.90086 12.6329 1.88007 10.7993L1.86068 9.09023L1.76937 8.95855C1.58847 8.69767 1.19384 8.59153 0.9039 8.72577Z"
          fill="#1D1D1D"
        />
      </svg>
      {children}
    </button>
  );
};
