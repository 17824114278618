export const TelegramIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 31 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.543587 12.9598L5.83061 14.9282C6.99116 15.3602 7.88614 16.3055 8.25421 17.4879L10.449 24.5384C10.5698 25.1414 11.2945 25.2621 11.7777 24.9002L15.764 21.6437C16.1264 21.2819 16.7304 21.2819 17.2136 21.6437L24.3406 26.83C24.8238 27.1918 25.5486 26.9506 25.6694 26.3476L30.9845 1.01934C31.1052 0.416287 30.5013 -0.18676 29.8973 0.0544611L0.543587 11.3919C-0.181196 11.6331 -0.181196 12.7186 0.543587 12.9598ZM9.79784 14.4595C9.96945 14.3141 10.1831 14.2275 10.375 14.1103L24.099 5.72315C24.1258 5.70977 24.1526 5.70233 24.1788 5.69984C24.563 5.66319 24.1974 6.21892 23.9155 6.4826L12.8649 16.8193C12.5025 17.1812 12.1401 17.6636 12.1401 18.2666L11.7777 21.1613C11.7777 21.5231 11.1737 21.6437 11.0529 21.1613L9.74891 16.3873C9.65234 16.0337 9.46126 15.699 9.43576 15.3334C9.41225 14.9962 9.54569 14.6731 9.79784 14.4595Z"
      fill="#F1F1F1"
    />
  </svg>
);
