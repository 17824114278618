export const PlanetBig = () => (
  <svg
    width="672"
    height="372"
    viewBox="0 0 672 372"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M491.664 215.985C475.078 301.917 391.922 358.154 305.928 341.593C219.935 325.033 163.67 241.946 180.255 156.015C196.841 70.0833 279.998 13.8464 365.991 30.4068C451.985 46.9672 508.25 130.054 491.664 215.985Z"
      fill="url(#paint0_linear_8_555)"
      stroke="url(#paint1_linear_8_555)"
      strokeWidth="0.720133"
    />
    <path
      d="M655.106 247.94C652.859 259.6 641.9 269.1 623.785 276.238C605.689 283.369 580.556 288.101 550.171 290.29C489.406 294.668 407.723 288.875 319.528 271.865C231.333 254.855 153.352 229.854 98.5697 203.19C71.1762 189.857 49.6028 176.117 35.4538 162.767C21.2898 149.403 14.6466 136.509 16.8937 124.849C19.1409 113.189 30.0998 103.689 48.2151 96.5507C66.3113 89.4196 91.4444 84.6883 121.829 82.4989C182.594 78.1206 264.277 83.9136 352.472 100.924C440.667 117.934 518.648 142.935 573.43 169.599C600.824 182.932 622.397 196.672 636.546 210.022C650.71 223.386 657.353 236.28 655.106 247.94Z"
      stroke="url(#paint2_linear_8_555)"
      strokeWidth="0.720133"
    />
    <path
      d="M630.401 231.042C628.337 241.754 618.279 250.491 601.639 257.062C585.017 263.625 561.931 267.984 534.019 270.006C478.201 274.05 403.167 268.742 322.155 253.117C241.142 237.492 169.514 214.513 119.199 190C94.0396 177.742 74.2281 165.11 61.2368 152.835C48.2307 140.546 42.1408 128.695 44.2053 117.983C46.2698 107.27 56.3273 98.5339 72.9681 91.963C89.5898 85.3995 112.676 81.0407 140.588 79.0187C196.406 74.9751 271.44 80.2826 352.452 95.9074C433.465 111.532 505.093 134.511 555.407 159.025C580.567 171.283 600.379 183.915 613.37 196.19C626.376 208.479 632.466 220.33 630.401 231.042Z"
      stroke="url(#paint3_linear_8_555)"
      strokeWidth="0.720133"
    />
    <path
      d="M601.182 216.583C599.339 226.145 590.371 233.954 575.512 239.834C560.672 245.706 540.059 249.61 515.134 251.426C465.29 255.057 398.288 250.331 325.948 236.379C253.608 222.426 189.652 201.895 144.73 179.987C122.266 169.031 104.581 157.74 92.987 146.77C81.378 135.785 75.9552 125.2 77.7979 115.639C79.6406 106.077 88.6083 98.2679 103.467 92.388C118.308 86.5156 138.921 82.6115 163.846 80.7958C213.69 77.1648 280.692 81.8912 353.032 95.8433C425.372 109.795 489.328 130.327 534.25 152.235C556.713 163.191 574.398 174.482 585.993 185.452C597.602 196.437 603.024 207.022 601.182 216.583Z"
      stroke="url(#paint4_linear_8_555)"
      strokeWidth="0.720133"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8_555"
        x1="543.036"
        y1="-200.81"
        x2="349.121"
        y2="167.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#47BCFC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8_555"
        x1="412.416"
        y1="-38.6084"
        x2="230.451"
        y2="388.037"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#47BCFC" />
        <stop offset="1" stopColor="#47BCFC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8_555"
        x1="294.711"
        y1="313.114"
        x2="340.522"
        y2="130.185"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8_555"
        x1="299.34"
        y1="291.098"
        x2="341.501"
        y2="122.808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8_555"
        x1="305.557"
        y1="270.391"
        x2="343.278"
        y2="119.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);